@use "common" as *;

.web-view {
    display: block !important;

    @include breakpoint-down(sm) {
        display: none !important;
    }
}

.responsive-view {
    display: none !important;

    @include breakpoint-down(sm) {
        display: grid !important;
    }
}

.responsive-calibration-container,
.responsive-calibration-field-container {
    display: grid;
    grid-gap: 20px;
    box-sizing: content-box;
    justify-content: start;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.responsive-calibration-field-container {
    margin-bottom: 1.5rem;
    word-break: break-all;

    &:last-child {
        margin-bottom: 0;
    }
}

.responsive-calibration-field-container.reason {
    grid-template-columns: 1fr;
}

.calibration-field-result {
    grid-template-rows: min-content;

    .input-section {
        margin-top: 7px;
        width: 75%;
        position: relative;
    }

    label {
        width: 75%;
        margin-top: 10px;
    }

    &.required-field {
        &:after {
            color: $red;
            font-size: 0.88rem;
            content: "*Required";

            @include breakpoint-down(sm) {
                content: "";
            }
        }

        input {
            border-width: 2px;
            border-color: $red;
        }
    }

    &.required-in-reference-material {
        &:after {
            content: "";
        }
    }
}

.responsive-view.slide-up-overlay {
    .header {
        .nav {
            display: grid;
            background-color: $layout-section-header-blue;

            .close {
                justify-self: end;
                text-transform: uppercase;
                font-family: EMprintRegular, HelveticaNeue, Helvetica, Arial, sans-serif;
                color: white;
            }

            h1 {
                font-size: 1em;
                color: white;
                font-size: 1.3em;
                margin: 0;
            }
        }

        .description {
            sub {
                font-size: 1.2rem;
            }

            h2 {
                color: $font_color_darkblue;
                font-size: 1.4em;
            }
        }
    }
}

@include breakpoint-down(md) {
    .responsive-calibration-field-container {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 0;
    }
}

@include breakpoint-down(sm) {
    .responsive-calibration-container,
    .responsive-calibration-field-container {
        width: calc(100% + 3rem);
        margin-left: -1.5rem;
        grid-template-columns: 1fr;
    }

    .responsive-calibration-field-container {
        border-bottom: 1px solid #eee7e7;

        &:last-child {
            border-bottom: none;
        }
    }

    .responsive-calibration-container {
        grid-gap: 10px;
    }

    .responsive-calibration-field-container {
        align-items: center;
        margin-bottom: 0;
        padding: 0;

        .calibration-field-result {
            width: auto;
            display: grid;
            box-sizing: border-box;
            grid-template-columns: 1fr 1fr;
            margin-top: 0;
            padding: 1rem 1.5rem;
            border-bottom: 1px solid $layout-bottom-border;
            align-items: center;
            grid-gap: 0 1.5rem;

            &.required-field {
                border-left: 6px solid $warning-orange;
            }

            &:last-child {
                border-bottom: none;
            }

            .input-section,
            label {
                width: 75%;
            }

            label {
                margin-top: 0;
            }

            span {
                width: 100%;
                margin-top: 0;
            }

            input,
            .input-section,
            .technician-input {
                margin-top: 0;
                margin-left: auto;
                margin-right: 0;
            }
        }
    }

    .calibration-info {
        width: auto;
        display: grid;
        box-sizing: border-box;
        grid-template-columns: 1fr 1fr;
        padding: 1rem 1.5rem;
        width: 100%;
        border-bottom: 1px solid $layout-bottom-border;
        align-items: center;

        p {
            text-align: end;
            justify-self: flex-end;
            margin: 0;
        }

        h6.description {
            text-align: end;
            justify-self: flex-end;
            margin: 0;
            grid-column: 2;

            color: #8e8e97;
            font-family: EMprintRegular;
            font-weight: 500;
            font-size: 0.95em;
        }

        input {
            justify-self: flex-end;
            margin: 0;
            width: 75%;
        }

        label {
            width: 100%;
        }

        &:last-child {
            border-bottom: none;
        }
    }

    .calibration-info .important-box-yellow {
        grid-template-columns: 1fr;
    }

    .em-c-field {
        margin-bottom: 0;
    }

    .responsive-spacing {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .responsive-view.slide-up-overlay {
        .header {
            .nav {
                .close {
                    padding: 1.5rem;
                }

                h1 {
                    padding: 0 1.5rem 1.5rem;
                }
            }

            .description {
                padding: 1.5rem;
            }
        }

        .body {
            padding: 0 1.5rem;
        }
    }
}

@include breakpoint-down(xs) {
    .responsive-calibration-container,
    .responsive-calibration-field-container {
        width: calc(100% + 2rem);
        margin-left: -1rem;
    }

    .responsive-calibration-container {
        grid-template-columns: 100%;
        grid-gap: 0;
        padding: 0;
    }

    .responsive-calibration-field-container {
        .calibration-field-result {
            grid-template-columns: 1fr;
            padding: 1rem;

            .input-section {
                width: 100%;
                margin-top: 10px;
            }

            label {
                width: 100%;
                margin-top: 0;
            }

            p {
                float: right;
                justify-self: end;
            }

            input,
            .input-section,
            .technician-input {
                justify-self: start;
                margin-left: unset;
            }
        }
    }

    .calibration-info {
        padding: 1rem;
    }

    .responsive-spacing {
        margin-top: 0;
        margin-bottom: 0;
    }

    .responsive-view.slide-up-overlay {
        .header {
            .nav {
                .close {
                    padding: 1rem;
                }

                h1 {
                    padding: 0 1rem 1rem;
                }
            }

            .description {
                padding: 1rem;
            }
        }

        .body {
            padding: 0 1rem;
        }
    }
}

div.responsive-spacing div:last-child {
    app-responsive-calibration-input section {
        border-bottom: none;
    }
}

.responsive {
    &-lg {
        @include breakpoint-down(sm) {
            display: none !important;
        }
    }

    &-sm {
        @include breakpoint(md) {
            display: none !important;
        }
    }
}
